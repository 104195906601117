import { Button } from '#app/components/ui/button.js'
import { Gradient } from '#app/components/ui/gradient.js'
import { type MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'
import Header from './components/header'
import { Footer } from './components/footer'
import { Icon, IconName } from '#app/components/ui/icon.js'

export const meta: MetaFunction = () => [{ title: 'Competitor Backlinks' }]

const features = [
	{
		icon: 'link-2',
		title: 'Comprehensive Backlink Reports',
		description:
			"Get detailed insights into your competitors' backlink profiles.",
	},
	{
		icon: 'laptop',
		title: 'Identify High-Value Links',
		description:
			'Discover which backlinks are driving the most traffic and authority to their sites.',
	},
	{
		icon: 'clock',
		title: 'Stay Ahead of the Game',
		description:
			'Get real-time data to understand how your competitors are performing.',
	},
	{
		icon: 'download',
		title: 'Boost Your Rankings',
		description:
			'Leverage competitor insights to strengthen your own SEO strategy.',
	},
	{
		icon: 'magnifying-glass',
		title: 'Save Time & Resources',
		description: 'Automated analysis eliminates manual tracking and guesswork.',
	},
	{
		icon: 'sun',
		title: 'Gain a Competitive Edge',
		description:
			'Outsmart competitors by understanding and improving upon their strategies.',
	},
]

const tiers = [
	{
		name: 'Solo' as const,
		slug: 'solo',
		description: 'Get an analysis and report on 1 website.',
		priceMonthly: 19,
		plan: 'price_1QFzHPD5Jbz2hEMYBu9jR7CC',
		highlights: [
			{ description: 'One website analysis' },
			{ description: 'One-time payment' },
			{ description: 'Detailed backlink report' },
			{ description: 'Backlink opportunities' },
			{ description: 'Niche specific backlink ideas' },
		],
	},
	{
		name: 'Competitive' as const,
		slug: 'competitive',
		description: 'Get an analysis and report on 3 websites.',
		priceMonthly: 39,
		plan: 'price_1QFzH7D5Jbz2hEMY5NV1a0gU',
		highlights: [
			{ description: 'Three website analyses' },
			{ description: 'One-time payment' },
			{ description: 'Detailed backlink report' },
			{ description: 'Backlink opportunities' },
			{ description: 'Niche specific backlink ideas' },
		],
	},
	{
		name: 'Domination' as const,
		slug: 'domination',
		description: 'Get an analysis and report on 5 websites.',
		priceMonthly: 49,
		plan: 'price_1QFzGmD5Jbz2hEMYObbD1dP8',
		highlights: [
			{ description: 'Five website analyses' },
			{ description: 'One-time payment' },
			{ description: 'Detailed backlink report' },
			{ description: 'Backlink opportunities' },
			{ description: 'Niche specific backlink ideas' },
		],
	},
]

export default function Index() {
	return (
		<>
			<div className="relative py-6">
				<Gradient className="rounded-4xl absolute inset-2 bottom-0 ring-1 ring-inset ring-black/5" />
				<Header />
				<div className="relative mx-auto max-w-2xl lg:max-w-7xl">
					<div className="px-8 pb-24 pt-16 sm:pb-32 sm:pt-24 md:pb-48 md:pt-32 xl:px-0">
						<h1 className="font-display text-balance text-6xl/[0.9] font-medium tracking-tight sm:text-8xl/[0.8] md:text-9xl/[1]">
							Competitor Backlink Analysis
						</h1>
						<p className="mt-8 max-w-lg text-xl/7 font-medium sm:text-2xl/8">
							Reveal the secrets propelling your competitors to the top of the
							search rankings. Then outrank them!
						</p>
						<div className="mt-12 flex flex-col gap-x-6 gap-y-4 sm:flex-row">
							<Button size="lg" asChild>
								<Link to="/pricing">Get Backlink Report</Link>
							</Button>
							{/* <Button size="lg" variant="ghost" asChild>
								<Link to="/pricing">See pricing</Link>
							</Button> */}
						</div>
					</div>
				</div>
			</div>

			<div className="mx-auto max-w-2xl py-32 lg:max-w-7xl">
				<h2 className="max-w-3xl text-pretty text-4xl font-medium tracking-tighter text-gray-950 dark:text-white sm:text-6xl">
					Know more about your competitors.
				</h2>
				<div className="mt-16 grid grid-cols-1 gap-8 lg:grid-cols-3">
					{features.map((feature) => (
						<div
							key={feature.title}
							className="relative flex flex-col rounded-3xl bg-white p-2 shadow-md shadow-black/5 ring-1 ring-black/5 dark:bg-gray-900"
						>
							<div className="flex flex-1 flex-col p-8">
								<div>
									<Icon
										name={feature.icon as IconName}
										className="size-9 rounded-lg bg-purple-500 p-2 text-white"
									/>
									<div className="mt-2 text-base/7 font-medium text-gray-950 dark:text-white">
										<span className="absolute inset-0" />
										{feature.title}
									</div>
									<div className="mt-2 flex-1 text-sm/6 text-gray-500">
										{feature.description}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="rounded-4xl mx-2 mt-2 bg-gray-900 py-32">
				<div className="mx-auto max-w-2xl lg:max-w-7xl">
					<h2 className="max-w-3xl text-pretty text-4xl font-medium tracking-tighter text-white sm:text-6xl">
						How our competitor backlink analysis works.
					</h2>

					<ul className="mt-16 grid list-none grid-cols-1 gap-8 lg:grid-cols-2">
						<li className="text-gray-300">
							<h3 className="text-2xl">
								<span className="bg-gradient-to-r from-[#fff1be] from-[28%] via-[#ee87cb] via-[70%] to-[#b060ff] bg-clip-text text-transparent">
									Submit Competitor URLs
								</span>
							</h3>
							<p className="mt-2 text-xl">
								Simply input the websites you want to analyze. We will use all
								the high-priced subscription tools to get the comprehensive
								backlink data on the websites.
							</p>
						</li>
						<li className="text-gray-300">
							<h3 className="text-2xl">
								<span className="bg-gradient-to-r from-[#fff1be] from-[28%] via-[#ee87cb] via-[70%] to-[#b060ff] bg-clip-text text-transparent">
									Scan & Analyze
								</span>
							</h3>
							<p className="mt-2 text-xl">
								Once the data is collected, we will analyze it and provide you
								with a report of your competitors backlink profile broken down
								by domain, anchor text, and more.
							</p>
						</li>
						<li className="text-gray-300">
							<h3 className="text-2xl">
								<span className="bg-gradient-to-r from-[#fff1be] from-[28%] via-[#ee87cb] via-[70%] to-[#b060ff] bg-clip-text text-transparent">
									Receive Actionable Insights
								</span>
							</h3>
							<p className="mt-2 text-xl">
								You then receive a detailed report of your competitors backlink
								profile. This data should help you understand how to improve
								your own SEO strategy and outrank your competitors.
							</p>
						</li>
						<li className="text-gray-300">
							<h3 className="text-2xl">
								<span className="bg-gradient-to-r from-[#fff1be] from-[28%] via-[#ee87cb] via-[70%] to-[#b060ff] bg-clip-text text-transparent">
									Sneaky Backlink Opportunities
								</span>
							</h3>
							<p className="mt-2 text-xl">
								If we find any high-quality backlink opportunities, we will
								provide you with a list of the top opportunities. These are
								opportunities that might be popular directories or apart of a
								guest post network.
							</p>
						</li>
					</ul>
				</div>
			</div>

			<div className="mx-auto max-w-2xl pt-32 lg:max-w-7xl">
				<h2 className="max-w-3xl text-pretty text-4xl font-medium tracking-tighter text-gray-950 dark:text-white sm:text-6xl">
					Simple Pricing
				</h2>
				<p className="mt-6 max-w-3xl text-2xl font-medium text-gray-500">
					SEO professionals all over the world understand the importance of
					analyzing their competitors backlink profiles. Get started today to
					understand how you can outrank your competitors.
				</p>
			</div>

			<div className="relative px-6 lg:px-8">
				<Gradient className="rounded-4xl absolute inset-x-2 bottom-0 top-56 ring-1 ring-inset ring-black/5" />
				<div className="relative mx-auto max-w-2xl pb-24 pt-32 lg:max-w-7xl">
					<div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
						{tiers.map((tier, tierIndex) => (
							<div
								key={tierIndex}
								className="rounded-4xl -m-2 grid grid-cols-1 shadow-[inset_0_0_2px_1px_#ffffff4d] ring-1 ring-black/5 max-lg:mx-auto max-lg:w-full max-lg:max-w-md"
							>
								<div className="rounded-4xl grid grid-cols-1 p-2 shadow-md shadow-black/5">
									<div className="rounded-3xl bg-white p-10 pb-9 shadow-2xl ring-1 ring-black/5 dark:bg-gray-900">
										<h3 className="font-mono text-xs/5 font-semibold uppercase tracking-widest text-gray-500 dark:text-gray-400">
											{tier.name}
										</h3>
										<p className="mt-2 text-sm/6 text-gray-950/75 dark:text-white">
											{tier.description}
										</p>
										<div className="mt-8 flex items-center gap-4">
											<div className="text-5xl font-medium text-gray-950 dark:text-white">
												${tier.priceMonthly}
											</div>
											<div className="text-sm/5 text-gray-950/75 dark:text-gray-400">
												<p>USD</p>
												<p>one-time payment</p>
											</div>
										</div>
										<div className="mt-8">
											<Button asChild>
												<Link to={`/checkout?plan=${tier.plan}`}>
													Get Started
												</Link>
											</Button>
										</div>
										<div className="mt-8">
											<h3 className="text-sm/6 font-medium text-gray-950 dark:text-gray-400">
												Includes:
											</h3>
											<ul className="mt-3 space-y-3">
												{tier.highlights.map((props, featureIndex) => (
													<li
														key={featureIndex}
														data-disabled={
															'disabled' in props && props.disabled
																? true
																: undefined
														}
														className="flex items-start gap-4 text-sm/6 text-gray-950/75 data-[disabled]:text-gray-950/25 dark:text-white dark:data-[disabled]:text-gray-400"
													>
														<span className="inline-flex h-6 items-center">
															<Icon
																name="check"
																className="size-[0.9375rem] shrink-0 fill-gray-950/25 dark:fill-white"
															/>
														</span>
														{props.description}
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

			<div className="mx-auto max-w-2xl pt-32 lg:max-w-7xl">
				<section id="faqs" className="scroll-mt-8">
					<h2 className="text-center font-mono text-xs/5 font-semibold uppercase tracking-widest text-gray-500 dark:text-gray-400">
						Frequently asked questions
					</h2>
					<p className="text-pretty text-center text-4xl font-medium tracking-tighter text-gray-950 dark:text-white sm:text-6xl">
						Your questions answered.
					</p>
					<div className="mx-auto mb-32 mt-16 max-w-xl space-y-12">
						<dl>
							<dt className="text-sm font-semibold">
								What tools do you use to get the backlink data?
							</dt>
							<dd className="mt-4 text-sm/6 text-gray-600 dark:text-gray-400">
								We use a combination of tools to get the backlink data. We
								currently use Ahrefs, Moz, and SEMrush. We pay for these tools
								ourselves, so you don't have to.
							</dd>
						</dl>
						<dl>
							<dt className="text-sm font-semibold">
								How long does it take to get the report?
							</dt>
							<dd className="mt-4 text-sm/6 text-gray-600 dark:text-gray-400">
								Once we receive the URLs, it usually takes about 24 hours to get
								the report. We will email you once it is complete.
							</dd>
						</dl>
						<dl>
							<dt className="text-sm font-semibold">
								What is the difference between Solo, Competitive, and
								Domination?
							</dt>
							<dd className="mt-4 text-sm/6 text-gray-600 dark:text-gray-400">
								These are different plans that allow you to analyze more or less
								websites. The Solo plan allows you to analyze one website, the
								Competitive plan allows you to analyze three websites, and the
								Domination plan allows you to analyze five websites.
							</dd>
						</dl>
						<dl>
							<dt className="text-sm font-semibold">
								What if I want to analyze more websites?
							</dt>
							<dd className="mt-4 text-sm/6 text-gray-600 dark:text-gray-400">
								Currently, we only offer three plans. If you need more, please
								contact us and we can discuss a custom plan.
							</dd>
						</dl>
						<dl>
							<dt className="text-sm font-semibold">
								What kind of support do you offer?
							</dt>
							<dd className="mt-4 text-sm/6 text-gray-600 dark:text-gray-400">
								We offer email support. If you have any questions, please
								contact us and we will get back to you as soon as possible. We
								aim to respond to all emails within 24 hours.
							</dd>
						</dl>
						<dl>
							<dt className="text-sm font-semibold">
								What if I want to analyze a website that is not on the first
								page of Google?
							</dt>
							<dd className="mt-4 text-sm/6 text-gray-600 dark:text-gray-400">
								We can analyze any website, even if it is not on the first page
								of Google. However, the more popular the website, the more data
								we will be able to gather.
							</dd>
						</dl>
						<dl>
							<dt className="text-sm font-semibold">
								What are the "sneaky" backlink opportunities?
							</dt>
							<dd className="mt-4 text-sm/6 text-gray-600 dark:text-gray-400">
								These are backlink opportunities that are not obvious to the
								average person. These are opportunities that might be popular
								directories or apart of a guest post network. We will provide
								you with a list of the top opportunities even if your
								competitors are not using them yet. In other words, this is a
								way to find low-hanging fruit to help you outrank your
								competitors.
							</dd>
						</dl>
						<dl>
							<dt className="text-sm font-semibold">
								Can you help me get my competitor's backlinks?
							</dt>
							<dd className="mt-4 text-sm/6 text-gray-600 dark:text-gray-400">
								As we analyze your competitors, we will provide you with a list
								of the opportunities. Some of these sites we might already have
								a working relationship with. This will speed up the process of
								getting the backlinks your competitors have.
							</dd>
						</dl>
					</div>
				</section>
			</div>

			<Footer />
		</>
	)
}
